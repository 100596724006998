<template>
    <div>
        <a-row type="flex" :gutter="[2, 5]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Ingresos a Caja</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza tus comprobantes de tus ingresos</span>
			</a-divider>

            <a-col flex="1 1 100px">
                <a-button 
                    block 
                    type="primary"
                    @click="openMenu('arqueocaja')" 
                    v-if="$can('conciliacion.menu.arqueo.de.caja') && config.habilitararqueocaja === 'ACTIVADO'"
                    >
                    <a-icon type="search" /> Arqueo Caja
                </a-button>
            </a-col>

            <a-col flex="1 1 100px">
                <a-button block type="dashed" @click="openModalConsultaOpciones()"><a-icon type="search" /> Consultar Opciones</a-button>
            </a-col>

            <a-col flex="1 1 200px">
                <a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_inicio)" 
                    @change="onChangeFechaInicio" 
                    :locale="locale"
                    placeholder="Fecha Inicio"
                    style="width: 100%"
                />
            </a-col>

            <a-col flex="1 1 200px">
                <a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_final)" 
                    @change="onChangeFechaFinal" 
                    :locale="locale"
                    placeholder="Fecha Final"
                    style="width: 100%"
                />
            </a-col>

            <a-col flex="1 1 350px">
                <a-input-search placeholder="Buscar por codigo" @search="onSearch">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="refrescar">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

        <br>

        <a-table 
            :columns="columns" 
            :data-source="ingresocajas" 
            :pagination="paginate"
            :loading="loading"
            :ellipsis="true"
            size="small"
            :rowKey="record => record.ingresocaja_id"
            :scroll="{ x: 980 }"
            @change="handlePagination"
            >

            <template slot="fecha_ingreso" slot-scope="item">
                {{ utils.formatDate(item.created_at) }}
            </template>

            <template slot="plancuenta" slot-scope="plancuenta">
                {{ plancuenta.nombre }}
            </template>

            <template slot="estado" slot-scope="estado">
                <a-tag color="green" v-if="estado === 'APROBADO'">
                    {{ estado }}
                </a-tag>
                <a-tag color="red" v-if="estado === 'ANULADO'">
                    {{ estado }}
                </a-tag>
            </template>

            <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                <a-row>
                    <a-col :span="12">
                        <a-list size="small" bordered><a-list-item>CODIGO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>SUCURSAL:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>DETALLE:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>ESTADO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>IMPORTE INGRESO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>CUENTA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>MONEDA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>USUARIO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>FECHA REGISTRO:</a-list-item></a-list>
                    </a-col>
                    <a-col :span="12">
                        <a-list size="small" bordered><a-list-item>{{ item.codigo }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.almacen.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.detalleIngreso }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.estado }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.importeIngreso }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.plancuenta.nombre }} - {{ item.plancuenta.nombre_corto }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.tipocambio }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.usuario.usuario }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.fechaIngreso }} {{ item.horaIngreso }}</a-list-item></a-list>
                    </a-col>
                </a-row>
            </span>

            <template slot="action" slot-scope="item">
                <a-dropdown>
                    <a-menu slot="overlay">
                        <a-menu-item :key="buttonKeyVerReporte">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span>{{ item.codigo }}</span>
                                </template>
                                <a href="#" @click.prevent="reporteCarta(item.ingresocaja_id)"><a-icon type="file-pdf" :style="{ fontSize: '18px', color: '#fa1d03' }"/> &nbsp;<strong>Comprobante PDF</strong></a>
                            </a-tooltip>
                        </a-menu-item>

                        <a-menu-item :key="buttonKeyImprimirReporte">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span>{{ item.codigo }}</span>
                                </template>
                                <a href="#" @click.prevent="imprimirTermico(item.ingresocaja_id)"><a-icon type="file-text" :style="{ fontSize: '18px', color: '#02c7c1' }"/> &nbsp;<strong>Comprobante Térmico</strong></a>
                            </a-tooltip>
                        </a-menu-item>

                        <a-menu-item v-if="$can('conciliacion.eliminar.comprobante.de.ingreso')" @click.once="eliminar(item)" :key="buttonKeyIngresoEliminar">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">{{ item.codigo }}</span>
                                </template>
                                <a-icon type="delete" :style="{ fontSize: '18px', color: '#cc2400' }"/> <strong>Eliminar</strong>
                            </a-tooltip>
                        </a-menu-item>
                    </a-menu>
                    <a-button> Acciones <a-icon type="down" /> </a-button>
                </a-dropdown>
            </template> 
        </a-table>

        <!-- MODAL CONSULTAS OPCIONES -->
        <a-modal
            v-model="modalConsultaOpciones"
            title="Consultar"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="350"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <a-row type="flex">
                    <a-col flex="1 1 200px" v-if="$can('conciliacion.consultar.comprobante.ingreso.por.sucursal')">
                        <label class="control-label">Sucursal:</label><br>
                        <a-select size="large" :value="consulta.almacen_id" @change="onSelectAlmacen" style="width: 100%">
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" v-if="$can('conciliacion.consultar.comprobante.ingreso.por.usuario')">
                        <label for="proveedor" class="control-label">Usuarios:</label>
                        <a-select size="large" :value="consulta.usuario_id" @change="onSelectUsuario"  style="width: 100%">
                            <a-select-option v-for="(value, index) in usuarios" :key="index" :value="value.usuario_id"> {{ value.usuario }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px">
                        <label class="control-label">Accion:</label>
                        <a-button size="large" type="primary" block @click="refrescar">
                            <a-icon type="reload" /> Reestablecer Opciones Busqueda
                        </a-button>
                    </a-col>
                </a-row>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalConsultaOpciones = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1060;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>
    
<script>
    const columns = [
        { 
            id: 'ingresocaja_id', 
            title: 'Codigo', 
            dataIndex: 'codigo',
            width: '10%'
        },
        { 
            id: 'ingresocaja_id',
            title: 'Cuenta', 
            dataIndex: 'plancuenta',
            scopedSlots: { customRender: 'plancuenta' },
            width: '25%'
        },
        { 
            id: 'ingresocaja_id',
            title: 'Ingreso', 
            dataIndex: 'importeIngreso',
            width: '24%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'ingresocaja_id', 
            title: 'Moneda', 
            dataIndex: 'tipocambio', 
            width: '13%'
        },
        { 
            id: 'ingresocaja_id',
            title: 'Estado', 
            dataIndex: 'estado',
            scopedSlots: { customRender: 'estado' },
            width: '10%'
        },
        { 
            id: 'ingresocaja_id',
            title: 'F.Ingreso', 
            dataIndex: '',
            scopedSlots: { customRender: 'fecha_ingreso' },
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'ingresocaja_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '12%'
        }
    ]

	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
    import router from '@/config/router'
	import Swal from 'sweetalert2'
    import { mapGetters, mapActions } from 'vuex'
    
    export default {
    
        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                columns,
                utils,
                consulta: {
                    almacen_id: 'Seleccionar',
                    usuario_id: 'Seleccionar',
                    fecha_inicio: '',
                    fecha_final: '',
                    keyword: ''
                },
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'ingresocaja_id',
                    order: 'descend'
                },
                buttonKeyIngresoReporte: 10,
                buttonKeyIngresoEliminar: 20,
                buttonKeyVerReporte: 30,
                buttonKeyImprimirReporte: 40,
                spinnerloading: false,
                loading: false,
                modalConsultaOpciones: false
            }
        },

        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('usuario', ['usuarios']),
            ...mapGetters('ingresocaja', ['ingresocajas']),
            ...mapGetters('configuracion', ['config'])
        },

        methods: {
            ...mapActions('ingresocaja', ['getPaginate', 'deleteIngresoCaja']),
            ...mapActions('almacen', ['getAlmacenListado']),
			...mapActions('usuario', ['getUsuarioListar']),

            openMenu (name) {
				router.push({ name: name })
			},

            openModalConsultaOpciones () {
                this.modalConsultaOpciones = true
            },
            
            getAlmacenes () {
                this.getAlmacenListado()
				.then(response => {
					this.consulta.almacen_id = this.user.almacenID.toString()
				})
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

            getTipoUsuarios () {
                this.getUsuarioListar()
				.then(response => {
                    this.consulta.usuario_id = this.user.usuario_id
                })
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

            clearData () {
                this.consulta.usuarioID = 'Seleccionar'
                this.consulta.fecha_inicio = ''
                this.consulta.fecha_final = ''
                this.consulta.keyword = ''
            },

            onSelectAlmacen (value) {
                this.consulta.almacen_id = value
                this.paginarResultados()
            },

            onSelectUsuario (value) {
                this.consulta.usuario_id = value
                this.paginarResultados()
            },

            onChangeFechaInicio (date, dateString) {
				this.consulta.fecha_inicio = dateString
			},

            onChangeFechaFinal (date, dateString) {
				this.consulta.fecha_final = dateString
                this.paginarResultados()
			},

            onSearch (value) {
                this.consulta.keyword = value
                this.paginarResultados()
            },

            refrescar (value) {
                this.clearData()
                this.paginarResultados()
            },

            // Paginate
            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'ingresocaja_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'ingresocaja_id',
                    order: 'descend'
                }
            },

            paginarResultados () {
                const consultaAlmacen = (this.consulta.almacen_id === 'Seleccionar') ? this.user.almacenID : this.consulta.almacen_id
                const consultaUsuario = (this.consulta.usuario_id === 'Seleccionar') ? this.user.usuario_id : this.consulta.usuario_id

				this.consulta.almacen_id = (this.$can('conciliacion.consultar.comprobante.ingreso.por.sucursal')) ? consultaAlmacen : this.user.almacen_id
				this.consulta.usuario_id = (this.$can('conciliacion.consultar.comprobante.ingreso.por.usuario')) ? consultaUsuario : this.user.usuario_id

                this.getPaginate({ ...this.paginate, ...this.consulta })
                .then(response => {
                    this.changePaginate(response.data.meta)
                    this.spinnerloading = false
                })
                .catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            eliminar (item) {
                Swal.fire({
                        title: 'Desea el eliminar el ingreso?',
                        text: 'Tome en cuenta que al eliminar este registro permanecerá anulado.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        this.deleteIngresoCaja(item.ingresocaja_id)
                        .then(response => {
                            if (response.status === 201) {
                                this.paginarResultados()
                                utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                                this.spinnerloading = false
                            }
                        }).catch(error => {
                            this.loading = false
                            utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyIngresoEliminar++
            },

            imprimirTermico (ingresocajaID) {
                utils.openRecibo(`/reporte/ingresocaja/termica/${ingresocajaID}`)
            },

            reporteCarta (ingresocajaID) {
                utils.openRecibo(`/reporte/ingresocaja/reporte/carta/${ingresocajaID}`)
            }
        },

        mounted () {
            this.getAlmacenes()
            this.getTipoUsuarios()
            this.paginarResultados()
        }
    }
</script>